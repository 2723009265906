.productContainer{
    width:150px;
    background:#d3d3d3;
    cursor:pointer;
    transition: all 0.5s 0s ease;
    padding-left:10px;
    padding-right:10px;
    padding-bottom:20px;
    @media (max-width: 375px) {
        width:100%;
        margin-left: 5px;
        margin-right: 5px;
    }
}
.productContainer:hover{
    opacity:0.6;
}

.productContainer4{
    width:150px;
    top:15px;
    background:#d3d3d3;
    cursor:pointer;
    /* background-image: -webkit-linear-gradient(transparent 0%,rgba(255,255,255,.3) 50%,transparent 50%,rgba(0,0,0,.1) 100%);
    background-image:         linear-gradient(transparent 0%,rgba(255,255,255,.3) 50%,transparent 50%,rgba(0,0,0,.1) 100%);
    box-shadow:1px 1px 19px 1px gray;
    box-shadow: 左右の向きpx  上下の向きpx ぼかしpx 広がりpx 色 内側指定; */
    transition: all 0.5s 0s ease;
    @media (max-width: 375px) {
        margin-left: 5px;
        margin-right: 5px;
    }
}
.productContainer4:hover{
    opacity:0.6;
}

.dummyContainer1{
    width:20%;
    background:black;
    cursor:pointer;
    /* background-image: -webkit-linear-gradient(transparent 0%,rgba(255,255,255,.3) 50%,transparent 50%,rgba(0,0,0,.1) 100%);
    background-image: linear-gradient(transparent 0%,rgba(255,255,255,.3) 50%,transparent 50%,rgba(0,0,0,.1) 100%);
    box-shadow:1px 1px 19px 1px gray;
    box-shadow: 左右の向きpx  上下の向きpx ぼかしpx 広がりpx 色 内側指定; */
    transition: all 0.5s 0s ease;
    /* transform:${props => props.coordinate} */
    @media (max-width: 375px) {
        margin-left: 5px;
        margin-right: 5px;
    }
}
.dummyIcon{
    height:40%;
    margin-top:20px;
    margin-bottom:10px;
    margin-left:auto;
    margin-right:auto;
    @media (max-width: 375px) {
        height:100px;
    }
}
.dummyName{
    height:50px;
    margin-top:20px;
    margin-bottom:10px;
    margin-left:40px;
    margin-right:40px;
    @media (max-width: 375px) {
        margin-bottom:0px;
    }
}
.dummyElement{
    height:30px;
    margin-top:20px;
    margin-bottom:10px;
    margin-left:80px;
    margin-right:80px;
    @media (max-width: 375px) {
        display:none;
    }
}